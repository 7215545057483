.search_content {
    padding: 0 23px;
}
.wrapper__search_single_product {
    border-bottom: 1px solid #d9d9d9;
}
.search_single_product {
    display: grid;
    grid-template-columns: 55px 1fr auto;
    gap: 8px;
    padding: 11px 0;
}
.search_single_product__image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
}
.search_single_product__image img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}
.search_single_product__name_price {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 8px;
    margin-bottom: 5px;
}
.search_single_product__name {
    display: block;
    line-height: 17px;
    font-weight: 700;
    max-height: 34px;
    overflow: hidden;
    color: var(--darkgrey);
}
.search_single_product__price {
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    text-align: right;
}
.search_form__results_hit__price__discount {
    color: #000;
    height: 26px;
    background: var(--monolithyellow);
    border-radius: 5px;
    padding: 0 5px;
    margin-right: 15px;
}
.search_single_product__info, .search_single_product__info_auto {
    display: grid;
    grid-template-columns: 1fr 1fr;
    font-size: 12px;
    line-height: var(--lineheight);
    color: #999;
}
.search_single_product__info__stock {
    display: inline-block;
    width: 10px;
    height: 10px;
    background: #999;
    border-radius: 50%;
    margin-right: 6px;
}
.instock .search_single_product__info__stock {
    background: var(--monolithlightgreen);
}
.low .search_single_product__info__stock {
    background: var(--monolithorange);
}
.outofstock .search_single_product__info__stock {
    background: var(--maincolor);
}
.search_results_show_all,
.search_results_show_all__button {
    display: block;
    line-height: 75px;
    font-weight: 700;
    text-align: center;
}
.search_results_show_all__button {
    color: var(--maincolor);
    margin: auto;
}
.buttons_cart__button_search {
 width: 50px !important;
}
.sell_in_search {
    color: var(--maincolor);
}
.quick_add_change_product_data__search {     
    display: flex;
    justify-content: flex-end;
    padding-bottom: 10px;
    margin-top: -8px;
}