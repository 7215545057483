.main_page_banner {
    position: relative;
    /* margin-bottom: 16px; */
}
.main_page_banner__single {
    display: flex;
    align-items: center;
    justify-content: center;
    /* height: 370px; */
    height: 270px;
    cursor: pointer;
}
.main_page_banner__single img {
    object-fit: cover;
    height: 100%;
    min-height: 290px;
    width: 100%;
}
.carousel .control-dots .dot {
    width: 15px !important;
    height: 4px !important;
    background: #d9d9d9 !important;
    border-radius: 2px !important;
    opacity: 1 !important;
    box-shadow: none !important;
}
.carousel .control-dots .dot.selected {
    background: var(--maincolor) !important;
}

.main_page h2 {
    margin-bottom: 25px;
}
.main_page__menu {
    list-style: none;
    display: flex;
    justify-content: space-between;
    margin: 0 23px 29px 23px;
    text-align: center;
}
.main_page__menu a {
    color: var(--darkgey);
}
.main_page__menu a:hover,
.main_page__menu a:active {
    color: var(--maincolor);
}
.main_page__menu__button {
    display: block;
    width: 96px;
    height: 50px;
    background: #F8F8F8;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    padding: 13px 0 0 0;
}
.main_page__menu__text {
    display: block;
    font-weight: 700;
}
.discover_more {
    display: block;
    margin-bottom: 20px;
    cursor: pointer;
}
.discover_more img {
    width: 100%;
    height: auto;
}

.main_logo img {
    width: 40%;
    height: auto;
}